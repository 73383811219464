<mat-toolbar [class.mat-elevation-z6]="true" class="navbar-fixed-height">
  <mat-toolbar-row class="navbar-fixed-height">
    <div style="margin: auto; display: flex; justify-content: space-evenly; width: 1200px; align-items: center;">
      <a class="logo-container" [routerLink]="['/home']">
        <img img src="{{ logoPath }}" alt="logo" class="logo-img" />
      </a>
      <div class="navigation-items_wrapper">
        <ul class="navigation-items">
          <li class="show-on-desktop">
            <a mat-button [routerLink]="['/home']" routerLinkActive="active-menu-item"
              [routerLinkActiveOptions]="{ exact: true }" (click)="setTitle('Bruce\'s | Home', 'home')">Home
              <div class="menu-item-line"></div>
            </a>
          </li>
          <li class="show-on-desktop">
            <a mat-button [routerLink]="['/advent_calendar']" routerLinkActive="active-menu-item"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Bruce\'s | Advent Calendar', 'advent calendar')">Advent Calendar
              <div class="menu-item-line"></div>
            </a>
          </li>
          <li class="show-on-desktop">
            <a mat-button [routerLink]="['/services']" routerLinkActive="active-menu-item"
              [routerLinkActiveOptions]="{ exact: true }" (click)="setTitle('Bruce\'s | Bookings', 'bookings')">Bookings
              <div class="menu-item-line"></div>
            </a>
          </li>
          <li class="show-on-desktop">
            <a mat-button [routerLink]="['/photos/my-photos']" routerLinkActive="active-menu-item"
              [routerLinkActiveOptions]="{ exact: true }" (click)="setTitle('Bruce\'s | Photos', 'photos')">Photos
              <div class="menu-item-line"></div>
            </a>
          </li>
          <li class="show-on-desktop" *ngIf="userActive">
            <a mat-button [routerLink]="['/profiles']" routerLinkActive="active-menu-item"
              [routerLinkActiveOptions]="{ exact: true }" (click)="setTitle('Bruce\'s | Profiles', 'profiles')">Profiles
              <div class="menu-item-line"></div>
            </a>
          </li>
          <li class="show-on-desktop">
            <a mat-button [routerLink]="['/billing']" routerLinkActive="active-menu-item"
              [routerLinkActiveOptions]="{ exact: true }" (click)="setTitle('Bruce\'s | Billing', 'billing')">Billing
              <div class="menu-item-line"></div>
            </a>
          </li>
          <li class="show-on-desktop">
            <a mat-button [routerLink]="['/settings']" routerLinkActive="active-menu-item"
              [routerLinkActiveOptions]="{ exact: true }" (click)="setTitle('Bruce\'s | Settings', 'settings')">Settings
              <div class="menu-item-line"></div>
            </a>
          </li>
          <li class="show-on-desktop">
            <a mat-button [routerLink]="['/contact']" routerLinkActive="active-menu-item"
              [routerLinkActiveOptions]="{ exact: true }" (click)="setTitle('Bruce\'s | Contact', 'contact')">Contact
              <div class="menu-item-line"></div>
            </a>
          </li>
          <!-- <li>
            <a mat-button href="#" class="navbar-link_icon">
              <mat-icon
                svgIcon="notification_icon"
                color="primary"
                class="icons"
              ></mat-icon>
            </a>
          </li> -->
          <li>
            <a mat-button (click)="contactUsDialog()" class="navbar-link_icon" [disableRipple]="true">
              <mat-icon svgIcon="chat_icon" color="primary" class="icons"></mat-icon>
            </a>
          </li>
          <li class="show-on-desktop">
            <a mat-button (click)="onLogOut()" class="navbar-link_icon">
              <mat-icon svgIcon="logout_icon" class="icons"></mat-icon>
            </a>
          </li>
          <li class="show-on-mobile">
            <button mat-icon-button [disableRipple]="true" id="menu_bar" (click)="onToggleSidenav()">
              <mat-icon svgIcon="menu_icon" class="icons" color="primary"></mat-icon>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>